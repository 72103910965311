
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonNumberInput from '@/components/common/CommonNumberInput.vue'
import CommonDateInput from '@/components/common/CommonDateInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { isEmail, isRequired, isZipCode, isPhone } from '@/utils/validation'
import { convertCommaIntoDotInputWithForm, convertDotIntoCommaInputWithForm } from '@/utils/convertInput'
import { dateDashNotationToDotWithForm, dateDotNotationToDashWithForm } from '@/utils/convertDate'
import { handleError } from '@/utils/handleError'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'

import { useCreatePerson, useUpdatePerson } from '@/api/person'

import { PersonInput, PersonOutput } from '@/api/types/person'
import { FormField, FORM_FIELDS_ENUM, DATA_TYPE } from '@/utils/types/formField'
import { isOfType } from '@/utils/types/isOfType'
import { useGetEnumItemsBasic } from '@/api/enumItem'

export default defineComponent({
  name: 'AddEditPersonDialog',
  components: {
    CommonAddEditDialog,
    CommonNumberInput,
    CommonDateInput,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    personToEdit: {
      type: Object as PropType<PersonOutput>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const isEditMode = computed(() => Boolean(props.personToEdit))

    const {
      exec: getEnumItemsBasic,
      data: enumItemsBasic,
      isLoading: isLoadingEnumItemsBasic,
    } = useGetEnumItemsBasic()
    getEnumItemsBasic({ params: { enumItemAssignment: 'PERSON_SPECIAL_ROLE' } })

    const FORM_FIELDS: FormField[] = [
      {
        value: 'firstName',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('persons.form.firstName') as string)],
      },
      {
        value: 'lastName',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('persons.form.lastName') as string)],
      },

      {
        value: 'birthdate',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'firstContacted',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'phonePrivate',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isPhone(value, root.$t('persons.form.phonePrivate') as string)],
      },
      {
        value: 'phoneMobile',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isPhone(value, root.$t('persons.form.phoneMobile') as string)],
      },
      {
        value: 'phoneCorporate',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isPhone(value, root.$t('persons.form.phoneCorporate') as string)],
      },
      {
        value: 'emailPrivate',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isEmail(value, root.$t('persons.form.emailPrivate') as string)],
      },
      {
        value: 'emailBusiness',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isEmail(value, root.$t('persons.form.emailBusiness') as string)],
      },
      {
        value: 'emailCorporate',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isEmail(value, root.$t('persons.form.emailCorporate') as string)],
      },
      {
        value: 'addressZipCode',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: false,
        rules: [(value: string) => isZipCode(value)],
      },
      {
        value: 'addressCity',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'address1',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'address2',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'addressCountry',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'specialRoleIds',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => enumItemsBasic.value ?? []),
        isLoading: computed(() => isLoadingEnumItemsBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
    ]

    const form = ref<PersonInput | PersonOutput>(
      isEditMode.value ? cloneDeep(props.personToEdit) : ({} as PersonInput)
    )

    const { createPerson, isLoading: isLoadingCreatePerson } = useCreatePerson()

    async function onAdd(): Promise<void> {
      if (!isOfType<PersonOutput>(form.value, 'id')) {
        try {
          let updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, form.value)
          updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

          await createPerson(updatedForm)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updatePerson, isLoading: isLoadingUpdatePerson } = useUpdatePerson()

    const RESET_PROPS_FOR_PUT_REQ = [
      'notes',
      'tasks',
      'contracts',
      'assignments',
      'activeContractualRelationships',
      'activeProfessionalUnits',
      'activeAssignmentProfiles',
    ]

    async function onEdit(): Promise<void> {
      if (isOfType<PersonOutput>(form.value, ['id'])) {
        try {
          let updatedForm = mapBasicEntityToIdWithForm<PersonOutput>(form.value)

          updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)
          updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

          RESET_PROPS_FOR_PUT_REQ.forEach((entry) => (updatedForm[entry] = []))

          await updatePerson(updatedForm.id, updatedForm)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditPerson = computed(() => isLoadingCreatePerson.value || isLoadingUpdatePerson.value)

    function close(): void {
      emit('added-edited')

      emit('close')
    }

    watch(
      () => props.personToEdit,
      () => {
        if (props.personToEdit) {
          form.value = props.personToEdit

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)

          form.value = convertDotIntoCommaInputWithForm(FORM_FIELDS, form.value)
        }
      },
      {
        immediate: true,
      }
    )

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditPerson,
      },
      listeners: {
        onAdd,

        onEdit,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
